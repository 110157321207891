.flexiday-logo {
  display: block;
  text-indent: -9999px;
  width: 160px;
  height: 40px;
  background: url("../img/flexiday_white.svg");
  background-size: 160px 40px;
  background-repeat: no-repeat;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}
